<template>
  <c-x-features type="monitor" class="Monitor">
    <c-x-traits-list
      :traits="traits"
      type="monitor"
    />
  </c-x-features>
</template>

<script>
import CXTraitsList from '@/components/cx/CXTraitsList'
import CXFeatures from '@/router/layouts/CXFeatures'

/**
 * @module Monitor
 */
export default {
  name: 'Monitor',
  components: { CXTraitsList, CXFeatures },
  data () {
    return {
      traits: [
        'global_monitoring', 'social', 'streams', 'mobile', 'notifications', 'share'
      ]
    }
  }
}
</script>
